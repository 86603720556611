@import '../../../styles/mixins.scss';

.footer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
  @include max-screen-530 {
    justify-content: space-between;
    min-width: 280px;
  }
  &__item {
    position: relative;
    margin-right: 66px;
    @include max-screen-530 {
      margin-right: 0;
    }
    &:before {
      content: '|';
      position: absolute;
      right: -34px;
      top: -1px;
      color: #575757;
      @include max-screen-530 {
        display: none;
      }
    }
    &:last-child {
      margin-right: 0;
      &:before {
        display: none;
      }
    }
  }
  &__link {
    color: #5976db;
    @include max-screen-530 {
      text-decoration: underline;
    }
    &:hover {
      color: #7a91e2;
      @include max-screen-530 {
        text-decoration: none;
      }
    }
  }
}
