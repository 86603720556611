@import '../../../styles/mixins.scss';

.versionSticker {
  position: absolute;
  left: 5px;
  bottom: 5px;
  padding: 2px 8px 3px;
  font-size: 14px;
  color: #fff;
  border-radius: 20px;
  background: #5976db;
  border: 1px solid #fff;
  @include max-screen-530 {
    font-size: 11px;
    left: 3px;
    bottom: 3px;
    padding: 1px 5px 2px;
  }
}
