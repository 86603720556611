.cardsControls {
  margin-bottom: 40px;
}

.cardsControls_mb {
  margin-bottom: 0;
}

.cardsControls__buttons {
  display: flex;
}

.cardsControls__button {
  width: 30px;
  height: 30px;
  border-radius: 4px;
  background-color: #f0f0f0;
  margin-right: 10px;
  cursor: pointer;
  position: relative;
}

.cardsControls__button:hover.cardsControls__button:after {
  opacity: 1;
}

.cardsControls__button:after {
  content: '';
  position: absolute;
  width: 16px;
  height: 16px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0.7;
  background-repeat: no-repeat;
  background-position: 0 0;
  background-size: cover;
  transition: opacity 0.3s ease;
}

.cardsControls__button_rows.cardsControls__button:after {
  background-image: url('/icons/cards-row-icon.svg');
}

.cardsControls__button_tiles.cardsControls__button:after {
  width: 18px;
  height: 18px;
  background-image: url('/icons/cards-tiles-icon.svg');
}

.cardsControls__button:last-child {
  margin-right: 0;
}

.cardsControls__button_active {
  background-color: #ffe4cc;
}

.cardsControls__button_active.cardsControls__button:after {
  opacity: 1;
}

@media screen and (max-width: 530px) {
  .cardsControls {
    margin-bottom: 30px;
  }

  .cardsControls_mb {
    margin-bottom: 0;
  }

  .cardsTitleWrap .cardsControls_mb {
    margin-bottom: 10px;
  }
}
