/*Мобильное меню*/
.mobileOverlay {
  display: none;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 0;
}

.pageWrapper main[menu-data='mobile-menu'] {
  height: 100vh;
  overflow: hidden;
  position: relative;
}

main[menu-data='mobile-menu'] .mobileOverlay {
  display: block;
}

main[menu-data='mobile-menu'] .mainContainer__menu {
  height: 100vh;
  overflow-y: scroll;
  border-radius: 0;
  z-index: 10;
}

.mainContainer {
  font-size: 14px;
  font-family: Arial, sans-serif;
  color: #000;
  display: flex;
  width: 100%;
  position: relative;
  background: #fff;
}

.mainContainer button,
.mainContainer select,
.mainContainer input,
.mainContainer textarea {
  font-family: Arial, sans-serif;
  outline: none;
}

.mainContainer__menu {
  flex: 0 0 250px;
  width: 250px;
  background: #ffe4cc;
  min-height: 100vh;
  position: relative;
  padding: 30px 25px;
}

.mainContainer__content {
  position: relative;
  width: calc(100% - 250px);
  padding: 20px 30px 90px 60px;
  box-sizing: border-box;
}

.mainContainer__topBanner {
  margin-bottom: 20px;
  height: auto;
  width: 100%;
  max-width: 1300px;
}

.mainContainer__wrap {
  width: 100%;
  max-width: 1300px;
}

.mainContainer__bottomBanner {
  margin-top: 40px;
  width: 100%;
  height: auto;
}

/*Media*/
@media screen and (max-width: 1300px) {
  .mainContainer__menu {
    flex: 0 0 200px;
    width: 200px;
    padding: 30px 20px;
  }

  .mainContainer__content {
    width: calc(100% - 200px);
    padding: 20px 30px 90px;
  }
}

@media screen and (max-width: 768px) {
  .mainContainer {
    display: block;
    padding: 0 16px;
  }

  .mainContainer__menu {
    width: 100%;
    border-radius: 0 0 8px 8px;
    padding: 8px 10px 10px;
    min-height: auto;
  }

  .mainContainer__content {
    position: relative;
    width: 100%;
    padding: 20px 0 90px;
  }
}
