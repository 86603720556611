@import '../../../styles/mixins.scss';

/* Карточка книги */
.bookCard {
  width: 50%;
  padding: 0 10px;
  margin-bottom: 20px;
  position: relative;
  display: flex;
  flex-direction: column;
}

.bookCard:after {
  content: '';
  position: absolute;
  right: 10px;
  bottom: 0;
  width: calc(100% - 20px);
  height: 1px;
  background-color: #d9d9d9;
}

.bookCard__wrap {
  display: flex;
}

.bookCard__image {
  width: 175px;
  height: auto;
  position: relative;
  margin: 0 20px 20px 0;

  background: #f9f9f9;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  overflow: hidden;
  &:before {
    content: ' ';
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 78px;
    height: 96px;
    background: url('/icons/book-no-cover.svg') no-repeat 50%;
    background-size: cover;
    z-index: 0;
    @include max-screen-530 {
      width: 52px;
      height: 64px;
    }
  }
}

.bookCard__imageLink {
  width: 100%;
  height: 100%;
  // position: relative;
  // display: block;
}

.bookCard__image img {
  position: relative;
  width: 100%;
  display: block;
  height: auto;
  color: transparent;
}

.bookCard__desc {
  flex: 1;
}

.bookCard__title {
  font-size: 20px;
  line-height: 28px;
  font-weight: bold;
  margin-bottom: 22px;
  padding-bottom: 0;
}

.bookCard__title a,
.bookCard__title a:hover {
  color: #000;
  text-decoration: none;
}

.bookCard__author {
  margin-bottom: 15px;
}

.bookCard__authorLink {
  font-size: 18px;
  font-weight: bold;
  color: #5a76db;
  text-decoration: none;
  transition: color 0.3s ease;
  &:hover {
    color: #7a91e2;
    text-decoration: none;
  }
  &_noHover {
    &:hover {
      color: #5a76db;
    }
  }
}

.bookCard__authorLink_name:hover {
  color: #5a76db;
}

.bookCard__rating {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 29px;
  font-size: 14px;
  margin-bottom: 20px;
}

.bookCard__rating:before {
  content: '';
  position: absolute;
  left: 0;
  top: 46%;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  background: url('/icons/star-icon.svg') no-repeat 0 0;
  background-size: cover;
}

.bookCard__ratingNumber {
  margin-right: 4px;
  color: #5976db;
}

.bookInfo__ratingVotes {
  color: #999;
}

.bookCard__txt {
  font-size: 14px;
  line-height: 21px;
  padding-bottom: 20px;
  position: relative;
}

.bookCard__txt_mob {
  display: none;
}

.bookCard__addDate {
  font-size: 13px;
  color: #d7d7d7;
  position: relative;
  top: -5px;
  margin-top: auto;
}

.bookCard__addDate_tile {
  display: none;
}

/*Версия без описания - карточки tiles*/

div[data-btn-cards='tiles'] ~ div[data-cards='list'] .bookCard {
  width: 264px;
  height: auto;
  margin-bottom: 20px;
}

div[data-btn-cards='tiles'] ~ div[data-cards='list'] .bookCard:hover .bookCard__desc {
  top: 0;
}

div[data-btn-cards='tiles'] ~ div[data-cards='list'] .bookCard__rating {
  margin-bottom: 0;
}

div[data-btn-cards='tiles'] ~ div[data-cards='list'] .bookCard__txt {
  display: none;
}

div[data-btn-cards='tiles'] ~ div[data-cards='list'] .bookCard__wrap {
  position: relative;
  border-radius: 8px;
  overflow: hidden;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

div[data-btn-cards='tiles'] ~ div[data-cards='list'] .bookCard__image {
  width: 264px;
  height: 346px;
  margin: 0;
  border: none;
  &:before {
    @include max-screen-530 {
      width: 78px;
      height: 96px;
    }
  }
}

div[data-btn-cards='tiles'] ~ div[data-cards='list'] .bookCard__image img {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 0;
  border: none;
}

div[data-btn-cards='tiles'] ~ div[data-cards='list'] .bookCard__imageLink {
  z-index: 0;
}

div[data-btn-cards='tiles'] ~ div[data-cards='list'] .bookCard__desc {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: -100%;
  background-color: rgba(73, 96, 176, 0.95);
  padding: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: top 0.4s ease-in-out;
  z-index: 1;
}

div[data-btn-cards='tiles'] ~ div[data-cards='list'] .bookCard:after {
  display: none;
}

div[data-btn-cards='tiles'] ~ div[data-cards='list'] .bookCard__title a,
div[data-btn-cards='tiles'] ~ div[data-cards='list'] .bookCard__title a:hover,
div[data-btn-cards='tiles'] ~ div[data-cards='list'] .bookCard__authorLink,
div[data-btn-cards='tiles'] ~ div[data-cards='list'] .bookCard__authorLink:hover,
div[data-btn-cards='tiles'] ~ div[data-cards='list'] .bookCard__authorLink_name:hover,
div[data-btn-cards='tiles'] ~ div[data-cards='list'] .bookCard__ratingNumber {
  color: #fff;
}

div[data-btn-cards='tiles'] ~ div[data-cards='list'] .bookCard__title {
  margin-bottom: 12px;
}

div[data-btn-cards='tiles'] ~ div[data-cards='list'] .bookCard__title a {
  font-size: 20px;
}

div[data-btn-cards='tiles'] ~ div[data-cards='list'] .bookCard__rating:before {
  background: url('/icons/star-white-icon.svg') no-repeat 0 0;
  background-size: cover;
}

div[data-btn-cards='tiles'] ~ div[data-cards='list'] .bookCard__title,
div[data-btn-cards='tiles'] ~ div[data-cards='list'] .bookCard__author {
  text-align: center;
}

div[data-btn-cards='tiles'] ~ div[data-cards='list'] .bookCard__addDate {
  display: none;
}

div[data-btn-cards='tiles'] ~ div[data-cards='list'] .bookCard__addDate_tile {
  top: 0;
  margin-top: 15px;
  display: inline-block;
  color: white;
}

div[data-btn-cards='tiles'] ~ div[data-cards='list'] .coverSticker {
  left: 0;
}

@media screen and (max-width: 1300px) {
  .bookCard {
    width: 100%;
  }

  div[data-btn-cards='tiles'] ~ div[data-cards='list'] .bookCard {
    width: 252px;
  }

  div[data-btn-cards='tiles'] ~ div[data-cards='list'] .bookCard__image {
    width: 252px;
    height: 330px;
  }

  div[data-btn-cards='tiles'] ~ div[data-cards='list'] .bookCard__title a {
    font-size: 18px;
  }

  div[data-btn-cards='tiles'] ~ div[data-cards='list'] .bookCard__authorLink {
    font-size: 16px;
  }
}

@media screen and (max-width: 1060px) {
  div[data-btn-cards='tiles'] ~ div[data-cards='list'] .bookCard__mobButton,
  div[data-btn-cards='tiles'] ~ div[data-cards='list'] .bookCard__hideButton {
    display: block;
    position: absolute;
    width: 40px;
    height: 40px;
    top: 5px;
    right: 5px;
    border-radius: 100%;
    background: white;
    cursor: pointer;
  }

  div[data-btn-cards='tiles'] ~ div[data-cards='list'] .bookCard__mobButton {
    right: 25px;
  }

  div[data-btn-cards='tiles'] ~ div[data-cards='list'] .bookCard__hideButton {
    background: rgba(255, 255, 255, 0.3);
  }

  div[data-btn-cards='tiles'] ~ div[data-cards='list'] .bookCard__mobButton:after {
    content: '';
    width: 24px;
    height: 23px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background: url('/icons/info-icon.svg') no-repeat 0 0;
    background-size: cover;
  }

  div[data-btn-cards='tiles'] ~ div[data-cards='list'] .bookCard__hideButton:after {
    content: '';
    width: 24px;
    height: 24px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background: url('/icons/close-icon.svg') no-repeat 0 0;
    background-size: cover;
    z-index: 3;
  }

  div[data-btn-cards='tiles'] ~ div[data-cards='list'] .bookCard:hover .bookCard__desc {
    top: -100%;
  }

  div[data-btn-cards='tiles'] ~ div[data-cards='list'] .bookCard_infoActive.bookCard:hover .bookCard__desc {
    top: 0;
  }

  div[data-btn-cards='tiles'] ~ div[data-cards='list'] .bookCard_infoActive .bookCard__desc {
    top: 0;
    z-index: 2;
  }
}

@media screen and (max-width: 530px) {
  .bookCard__image {
    width: 102px;
    margin: 0 15px 15px 0;
  }

  .bookCard__desc {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: -24px;
  }

  .bookCard__title {
    margin-bottom: 14px;
    line-height: 18px;
  }

  .bookCard__title a {
    font-size: 18px;
  }

  .bookCard__authorLink {
    font-size: 14px;
  }

  .bookCard__rating {
    margin-bottom: 0;
  }

  div[data-btn-cards='tiles'] ~ div[data-cards='list'] .bookCard__desc {
    margin-top: 0;
  }

  div[data-btn-cards='tiles'] ~ div[data-cards='list'] .bookCard__title {
    font-size: 18px;
    margin-bottom: 15px;
    line-height: 24px;
  }

  .bookCard__txt {
    display: none;
  }

  .bookCard__txt_mob {
    display: block;
  }
}

@media screen and (max-width: 378px) {
  div[data-btn-cards='tiles'] ~ div[data-cards='list'] .bookCard {
    width: 180px;
    padding: 0 5px;
    margin-bottom: 10px;
  }

  div[data-btn-cards='tiles'] ~ div[data-cards='list'] .bookCard__image {
    width: 170px;
    height: 240px;
  }

  div[data-btn-cards='tiles'] ~ div[data-cards='list'] .bookCard__title {
    margin-bottom: 10px;
  }

  div[data-btn-cards='tiles'] ~ div[data-cards='list'] .bookCard__title a {
    font-size: 16px;
  }

  div[data-btn-cards='tiles'] ~ div[data-cards='list'] .bookCard__authorLink {
    font-size: 14px;
  }

  div[data-btn-cards='tiles'] ~ div[data-cards='list'] .bookCard__mobButton {
    right: 5px;
    width: 28px;
    height: 28px;
  }

  div[data-btn-cards='tiles'] ~ div[data-cards='list'] .bookCard__mobButton:after {
    width: 16px;
    height: 15px;
  }

  div[data-btn-cards='tiles'] ~ div[data-cards='list'] .bookCard__hideButton {
    width: 15px;
    height: 15px;
    top: 5px;
    right: 5px;
    background: none;
  }

  div[data-btn-cards='tiles'] ~ div[data-cards='list'] .bookCard__hideButton:after {
    width: 16px;
    height: 16px;
    background: url('/icons/close-white-icon.svg') no-repeat 0 0;
    background-size: cover;
  }
}
