@import '../../../../styles/mixins.scss';

.sideBanner {
  display: block;
  &__link {
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 1;
  }
  &__img {
    width: 100%;
    height: auto;
    display: block;
    @include max-screen-768 {
      display: none;
    }
    &_mobile {
      display: none;
      @include max-screen-768 {
        display: block;
      }
    }
  }
}
