.mainContainer__menuBtn {
  display: none;
  cursor: pointer;
}

//Лого
.logotype {
  position: relative;
  text-align: center;
  margin-bottom: 30px;
}

.logotype__link {
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: center;
}

.logotype__img_mob {
  display: none;
}

//Поиск
.searchForm {
  position: relative;
  margin-bottom: 26px;
}

.searchForm__input {
  display: block;
  width: 100%;
  height: 32px;
  border: none;
  font-size: 14px;
  background: #fff;
  color: #575757;
  outline: none;
  border-radius: 3px;
  box-sizing: border-box;
  padding: 0 24px 0 7px;
  transition: all 0.3s ease 0s;
}

.searchForm__button {
  position: absolute;
  right: 4px;
  top: 50%;
  transform: translateY(-50%);
  overflow: hidden;
  cursor: pointer;
  display: block;
  padding: 0;
  color: transparent;
  border: none;
  outline: none;
  width: 18px;
  height: 18px;
  background: url('/icons/search-icon.svg') no-repeat 0 0;
  background-size: cover;
}

//Пункты меню
.menuList {
  position: relative;
  margin-bottom: 40px;
}

.menuList_border {
  position: relative;
  padding-top: 20px;
}

.menuList_border:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: #5976db;
  opacity: 0.4;
}

.menuList__item {
  margin-bottom: 20px;
  color: #575757;
  &::first-letter {
    text-transform: uppercase;
  }
}

.menuList__item_title {
  color: #575757;
  font-weight: bold;
  position: relative;
  padding-left: 15px;
}

.menuList__item_title:before {
  content: '';
  position: absolute;
  left: 0;
  top: 42%;
  opacity: 0.8;
  transform: translateY(-50%);
  width: 10px;
  height: 12.5px;
  background: url('/icons/book-icon.svg') no-repeat 0 0;
  background-size: cover;
}

.menuList__item_titleFeedBack {
  padding-left: 17px;
}

.menuList__item_titleFeedBack:before {
  width: 12px;
  height: 10.5px;
  background: url('/icons/email-icon.svg') no-repeat 0 0;
  background-size: cover;
}

.menuList__item_toggle {
  cursor: pointer;
  color: #5976db;
  position: relative;
}

.menuList__item_toggle:after {
  content: '';
  position: absolute;
  left: 80px;
  top: 40%;
  border: 4px solid transparent;
  border-top: 4px solid #5976db;
}

.menuList__item_toggle.active:after {
  top: 15%;
  border: 4px solid transparent;
  border-bottom: 4px solid #5976db;
}

.menu_genres {
  display: none;
}

.menuList__item_toggle.active ~ .menu_genres {
  display: block;
}

.menuList__item:last-child {
  margin-bottom: 0;
}

.menuList__item a {
  margin-bottom: 20px;
  color: #575757;
  font-size: 14px;
  transition: color 0.3s ease;
  text-decoration: none;
}

.menuList__item a:hover {
  color: #000;
  text-decoration: none;
}

.menuList__item_toggle,
.menuList__item_link a,
.menuList__item .ref,
.ref,
.link,
.bookInfo__categoryLink {
  text-decoration: none;
  color: #5976db;
}

.menuList__item_toggle:hover,
.menuList__item_link a:hover,
.menuList__item .ref:hover,
.ref:hover,
.link:hover,
.bookInfo__categoryLink:hover {
  color: #7a91e2;
  text-decoration: none;
}

@media screen and (max-width: 768px) {
  .mainContainer__menuBtn {
    position: absolute;
    left: 10px;
    top: 20px;
    display: block;
    width: 20px;
    height: 18px;
  }

  /*Бургер мобильное меню*/
  .burger__bottom,
  .burger__footer,
  .burger__topper {
    position: relative;
    display: block;
    width: 100%;
    height: 2px;
    background: #5976db;
    border: none;
    border-radius: 4px 4px 4px 4px;
    transition: all 0.2s, top 0.2s;
    transition-delay: 0.1s, 0s;
  }

  .burger__topper {
    top: 0;
  }

  .burger__bottom {
    top: 4px;
  }

  .burger__footer {
    top: 8px;
  }

  main[menu-data='mobile-menu'] .burger__topper {
    top: 4px;
    transform: rotate(-45deg);
  }

  main[menu-data='mobile-menu'] .burger__bottom {
    top: 2px;
    opacity: 0;
  }

  main[menu-data='mobile-menu'] .burger__footer {
    opacity: 1;
    top: 0;
    transform: rotate(45deg);
  }

  main[menu-data='mobile-menu'] .menuList_mobToggle {
    display: block;
  }

  //Лого
  .logotype__img {
    display: none;
  }

  .logotype__img_mob {
    display: block;
    width: 100%;
    height: auto;
  }

  .logotype {
    width: 110px;
    height: auto;
    margin: 0 auto 10px;
  }

  .searchForm {
    margin-bottom: 0;
  }

  /*мобильное меню*/
  .menuList {
    margin-bottom: 20px;
  }

  .menuList_mobNone,
  .menuList_mobToggle {
    display: none;
  }

  .menuList__item_title {
    padding-left: 0;
  }

  .menuList__item_title:before {
    display: none;
  }

  .menuList_main {
    margin-top: 20px;
  }
}
