.mainTitle {
  font-size: 32px;
  font-weight: bold;
  line-height: 1.3;
  color: #000;
  padding: 0;
  margin-bottom: 30px;
  &::first-letter {
    text-transform: uppercase;
  }
}

.mainTitle_filter {
  margin-bottom: 20px;
}

.mainTitle_error {
  margin-bottom: 10px;
}

.mainTitle_cards_btns {
  margin-bottom: 0;
}

@media screen and (max-width: 820px) {
  .mainTitle {
    font-size: 24px;
  }
}

@media screen and (max-width: 530px) {
  .cardsTitleWrap {
    margin-bottom: 10px;
  }

  .mainTitle {
    font-size: 20px;
    margin-bottom: 20px;
  }

  .mainTitle_cards_btns,
  .mainTitle_error {
    margin-bottom: 10px;
  }
}
