.rtbBanner_mobile {
  display: none;
}

@media screen and (max-width: 768px) {
  .rtbBanner_desktop {
    display: none;
  }

  .rtbBanner_mobile {
    display: block;
  }
}
